import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftReimmatricolazione, RightReimmatricolazione } from '../../components/pratiche-auto/reimmatricolazione-veicolo/ReimmatricolazioneVeicolo';

const ReimmatricolazioneVeicolo = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="reimmatricolazione-veicolo" title="Reimmatricolazione Veicolo">
                <LeftReimmatricolazione />
                <RightReimmatricolazione />
            </ContentPratica>

        </Layout>
    )
}

export default ReimmatricolazioneVeicolo
