import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

const LeftReimmatricolazione = () => {
  return (
    <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="sm:mb-4 mb-4 px-8 py-2">
        <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">
          Prezzo
        </h2>
      </div>
      <div className="flex-col space-y-8">
        {/* Price Item for ciclomotore */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              €95
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            in caso di ciclomotore
          </p>
        </div>

        {/* Price Item for motoveicolo */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              €160
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            in caso di motoveicolo
          </p>
        </div>

        {/* Price Item for autoveicolo */}
        <div className="sm:w-full text-center">
          {/* Image */}
          <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
            <p className="leading-relaxed font-bold text-4xl text-white">
              €180
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
          {/* Description */}
          <p className="text-center text-gray-700 text-xl mt-4">
            in caso di autoveicolo
          </p>
        </div>
      </div>
    </div>
  );
};

const RightReimmatricolazione = () => {
  return (
    <div className="w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left mx-auto">
      <div className="">
        <div className="text-2xl my-4 text-black">
          <p>
            In caso di deterioramento o smarrimento di una/più targhe del
            veicolo, è necessario procedere con la reimmatricolazione al fine di
            sostituire le stesse con targhe nuove.
          </p>
          <p>
            Per effettuare la reimmatricolazione di un veicolo sono necessari i
            seguenti documenti:
          </p>
        </div>
        <ul className="list-disc pl-6">
          <li className="text-gray-700 text-xl">Certificato di proprietà</li>
          <li className="text-gray-700 text-xl">
            Documento unico (DU) o carta di circolazione
          </li>
          <li className="text-gray-700 text-xl">
            Documento d'identità del proprietario in corso di validità
          </li>
          <li className="text-gray-700 text-xl">
            Permesso di soggiorno (in caso di proprietario extracomunitario)
          </li>
          <li className="text-gray-700 text-xl">
            Targa deteriorata/non smarrita
          </li>
          <li className="text-gray-700 text-xl">
            Denuncia di smarrimento (in caso di smarrimento targa)
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftReimmatricolazione, RightReimmatricolazione };
